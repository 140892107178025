@import "@/assets/scss/style";

.markdown {
	padding: 1px;

	:global {
		h2 {
			margin-bottom: 25px;
		}
		p:not(p:last-child) {
			margin-bottom: 20px;
		}
	}
}
