@import "@/assets/scss/style";
.cta-area-v1 {
	position: relative;
	margin-top: -100px;
	& .cta-item {
		padding: 60px;
		display: flex;
		position: relative;
		z-index: 1;
		@media #{$lg} {
			padding: 30px 20px;
		}
		@media #{$xm} {
			padding: 30px 20px;
		}
		@media #{$xss} {
			padding: 30px 15px;
		}
		&:hover {
			&:after {
				background-color: rgba(255, 117, 84, 0.85);
			}
			& .icon {
				& i {
					background-color: $heading-color;
				}
			}
		}
		&:after {
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(14, 27, 44, 0.75);
			z-index: -1;
			@include transition(0.3s);
		}
		& .icon {
			& i {
				width: 80px;
				height: 80px;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: $theme-color;
				color: $white;
				font-size: 24px;
				@include transition(0.3s);
				@media #{$xss} {
					width: 60px;
					height: 60px;
				}
			}
		}
		& .info {
			margin-left: 30px;
			@media #{$xss} {
				margin-left: 15px;
			}
			& h4 {
				margin-bottom: 10px;
				@media #{$xs} {
					font-size: 17px;
					line-height: 28px;
				}
				@media #{$xss} {
					font-size: 12px;
					line-height: 22px;
				}
			}
			& h3 {
				@media #{$xs} {
					font-size: 26px;
					line-height: 38px;
				}
				@media #{$xss} {
					font-size: 18px;
					line-height: 28px;
				}
			}
			& h4,
			& h3 {
				color: $white;
			}
		}
	}
}
.cta-area-v2 {
	position: relative;
	& .cta-bg {
		position: absolute;
		right: 0;
		top: 0;
		width: 50%;
		height: 100%;
		border-top-left-radius: 200px;
		border-bottom-left-radius: 200px;
		@media #{$xm} {
			display: none;
		}
	}
	& .section-title {
		& span.span {
			color: $white;
			&:before {
				background-color: $white;
			}
		}
	}
	& .button-box {
		& .main-btn {
			background-color: $white;
			color: $heading-color;
			&:hover,
			&:focus {
				background-color: $heading-color;
				color: $white;
			}
		}
	}
}
