@import "@/assets/scss/style";

.is-invalid {
	border: 1px solid $error-color;
}

.error {
	display: block;
	position: absolute;
	bottom: -25px;
	padding: 0 20px;
}
