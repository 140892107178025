@import "@/assets/scss/style";
.testimonial-area-v1{
    & .container-fluid{
        padding-left: 75px;
        padding-right: 75px;
        @media #{$lg}{
            padding-left: 15px;
            padding-right: 15px;
        }
        @media #{$xm}{
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    & .testimonial-item{
        position: relative;
        background-color: $white;
        padding: 50px 30px 45px;
        box-shadow: 0px 12px 30px 0px rgba(174, 174, 174, 0.2);
        @include transition(.3s);
        @media #{$xl} {
            padding: 50px 50px 45px;
        }
        @media #{$lp} {
            padding: 50px 30px 45px;
        }
        @media #{$xs} {
            padding: 50px 20px 45px;
        }
        &:hover{
            background-color: $theme-color;
            & .tl-title-thumb{
                & .tl-title{
                    & h4{
                        color: $white;
                    }
                    & p.position{
                        color: $white;
                    }
                }
            }
            & .tl-content{
                & p{
                    color: $white;
                }
            }
            &:before{
                color: rgba(255, 255, 255, 0.2);
            }
        }
        &:before{
            position: absolute;
            top: 50px;
            right: 30px;
            width: 60px;
            height: 60px;
            color: $theme-color;
            font-size: 60px;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: "\f10e";
            @media #{$xl} {
                right: 50px;
            }
            @media #{$lp}{
                font-size: 30px;
                top: 20px;
                right: 20px;
                width: 30px;
                height: 30px;
            }
            @media #{$xm}{
                display: none;
            }
        }
        & .tl-title-thumb{
            margin-bottom: 30px;
            & .tl-thumb{
                min-width: 70px;
                max-width: 70px;
                height: 70px;
            }
            & .tl-title{
                margin-left: 25px;
                & h4{
                    @media #{$xss}{
                        font-size: 19px;
                        line-height: 29px;
                    }
                }
                & p.position{
                    color: $theme-color;
                }
            }
        }
    }
}
.testimonial-area-v2{
    & .section-title{
        position: relative;
        z-index: 2;
    }
    & .tl-container{
        position: relative;
        z-index: 1;
        &:after{
            position: absolute;
            content: '';
            top: 50px;
            left: 50%;
            @include transform(translatex(-50%));
            background: url(/static/img/map.png) no-repeat center;
            background-size: 100%;
            width: 100%;
            height: 100%;
            z-index: -1;
        }
    }
    & .testimonial-item{
        & .tl-title-thumb{
            & .tl-thumb{
                position: relative;
                margin-bottom: 25px;
                & img{
                    margin: 0 auto;
                }
                & i{
                    position: absolute;
                    top: -5px;
                    left: 52%;
                    display: block;
                    width: 40px;
                    height: 40px;
                    line-height: 30px;
                    border-radius: 50%;
                    text-align: center;
                    background-color: $theme-color;
                    border: 5px solid $white;
                    color: $white;
                    font-size: 16px;
                }
            }
            & .tl-title{
                margin-bottom: 15px;
                & h4{
                    font-weight: 600;
                }
                & p.position{
                    color: $theme-color;
                }
            }
        }
    }
    & .testimonial-wrapper{
        position: relative;
        & .testimonial-dots-images{
            width: 100%;
            & .slick-dots{
                & li{
                    display: block;
                    margin: 0;
                    &:nth-child(1),&:nth-child(4){
                        position: absolute;
                        top: 6%;
                    }
                    &:nth-child(1){
                        left: 155px;
                    }
                    &:nth-child(4){
                        right: 155px;
                    }
                    &:nth-child(3),&:nth-child(5){
                        position: absolute;
                        top: 50%;
                        @include transform(translateY(-50%));
                    }
                    &:nth-child(3){
                        left: 0;
                    }
                    &:nth-child(5){
                        right: 0;
                    }
                    &:nth-child(2),&:nth-child(6){
                        position: absolute;
                        bottom: 0%;
                    }
                    &:nth-child(2){
                        left: 105px;
                    }
                    &:nth-child(6){
                        right: 105px;
                    }
                }
            }
        }
    }
}
