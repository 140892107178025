@import "@/assets/scss/style";

.is-invalid {
	border: 1px solid $error-color !important;
}

.select {
	display: flex;
    align-items: center;
	width: 100%;
	height: 55px;
	padding: 0 10px;
	border: none;
	margin-bottom: 30px;
}

.error {
	display: block;
	position: absolute;
	bottom: -25px;
	padding: 0 20px;
}
