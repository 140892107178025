@import "@/assets/scss/style";
.contact-map-section{
    & .map_box{
        & iframe{
            border: none;
            display: inherit;
            width: 100%;
            height: 550px;
        }
    }
}
