// Font Family
$poppins: 'Poppins', sans-serif;

$roboto: 'Roboto', sans-serif;


// Colors ---------------
$white: #fff;

$theme-color: rgb(255, 112, 87);

$text-color: #555b63;

$border-color: #e5e5e5;

// Heading Color
$heading-color: #0e1b2c;

// Bg color
$light-bg: #f4f4f4;

$error-color: #dc3545;

// Responsive Variables
$lp: 'only screen and (min-width: 1200px) and (max-width: 1600px)';
$xl: 'only screen and (min-width: 1200px)';
$lg : 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md:'only screen and (min-width: 768px) and (max-width: 991px)';
$xm:'(max-width: 991px)';
$xs:'(max-width: 767px)';
$xss:'(max-width: 575px)';
$sm: 'only screen and (min-width: 576px) and (max-width: 767px)';
