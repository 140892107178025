@import "@/assets/scss/style";

.backToTop {
  background: $theme-color;
  border-radius: 50%;
  bottom: 30px;
  color: $white;
  cursor: pointer;
  display: block;
  font-size: 20px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  position: fixed;
  right: 30px;
  text-align: center;
  text-decoration: none;
  transition: .3s;
  z-index: 337;
}
.backToTop:hover,
.backToTop:focus {
  background: $heading-color;
  color: $white;
}
