@mixin transition($time) {
  -webkit-transition: all $time ease-out 0s;
  -moz-transition: all $time ease-out 0s;
  -ms-transition: all $time ease-out 0s;
  -o-transition: all $time ease-out 0s;
  transition: all $time ease-out 0s;
}


@mixin transform($value) {
  -webkit-transform: $value;
     -moz-transform: $value;
      -ms-transform: $value;
       -o-transform: $value;
          transform: $value;
}

@mixin box-sizing($value) {
  -webkit-box-sizing: $value;
     -moz-box-sizing: $value;
          box-sizing: $value;
}

@mixin box-shadow($value) {
  -webkit-box-shadow: $value;
     -moz-box-shadow: $value;
          box-shadow: $value;
}


// Placeholder Mixins
@mixin placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}