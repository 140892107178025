@import "@/assets/scss/style";

@import '~@nextindex/next-scss/next-scss.scss';
.Cookie {
	position: fixed;
	overflow: hidden;
	box-sizing: border-box;
	z-index: 9999;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	flex-direction: column;
	> * {
		margin: rem(15) 0;
		align-self: center;
	}
	@include media($sm-up) {
		flex-flow: row;
		> * {
			margin: 0;
		}
	}
}
.Cookie--top {
	top: 0;
	left: 0;
	right: 0;
}
.Cookie--bottom {
	bottom: 0;
	left: 0;
	right: 0;
}
.Cookie__buttons {
	display: flex;
	flex-direction: column;
	> * {
		margin: rem(5) 0;
	}
	@include media($sm-up) {
		flex-direction: row;
		> * {
			margin: 0 rem(15);
		}
	}
}
.Cookie__button {
	cursor: pointer;
	align-self: center;
	white-space: nowrap;
}
.Cookie__declineButton {
	cursor: pointer;
	align-self: center;
	white-space: nowrap;
}
@mixin generateTheme(
	$theme,
	$backgroundColor,
	$fontColor,
	$buttonBackgroundColor,
	$buttonFontColor: #fff,
	$buttonRadius: 0
) {
	.Cookie--#{$theme} {
		background: $backgroundColor;
		color: $fontColor;
		padding: 1.25em;
		.Cookie__button {
			background: $buttonBackgroundColor;
			padding: 0.625em 3.125em;
			color: $buttonFontColor;
			border-radius: $buttonRadius;
			border: 0;
			font-size: 1em;
			&:hover {
				background: darken($buttonBackgroundColor, 10%);
			}
		}
		.Cookie__button--decline {
			background: transparent;
			padding: 0.625em 3.125em;
			color: darken($backgroundColor, 50%);
			border-radius: $buttonRadius;
			border: 0;
			font-size: 1em;
			&:hover {
				background: darken($backgroundColor, 15%);
			}
		}
	}
}
@include generateTheme('base', #f1f1f1, #232323, #006371);
@include generateTheme('base--rounded', #f1f1f1, #232323, #97d058, #fff, 20px);
@include generateTheme('blood-orange', #424851, #fff, #e76a68);
@include generateTheme('blood-orange--rounded', #424851, #fff, #e76a68, #fff, 20px);
@include generateTheme('dark-lime', #424851, #fff, #97d058);
@include generateTheme('dark-lime--rounded', #424851, #fff, #97d058, #fff, 20px);
@include generateTheme('royal', #fbc227, #232323, #726cea, #fff);
@include generateTheme('royal--rounded', #fbc227, #232323, #726cea, #fff, 20px);
.slideFromTop-enter,
.slideFromTop-leave-to {
	transform: translate(0px, -12.5em);
}
.slideFromTop-enter-to,
.slideFromTop-leave {
	transform: translate(0px, 0px);
}
.slideFromBottom-enter,
.slideFromBottom-leave-to {
	transform: translate(0px, 12.5em);
}
.slideFromBottom-enter-to,
.slideFromBottom-leave {
	transform: translate(0px, 0px);
}
.slideFromBottom-enter-active,
.slideFromBottom-leave-active,
.slideFromTop-enter-active,
.slideFromTop-leave-active {
	transition: transform 0.4s ease-in;
}
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
