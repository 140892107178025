@import "@/assets/scss/style";
.faq-v1 {
	& .faq-img {
		display: flex;
		justify-content: flex-end;

		@media #{$xm} {
			justify-content: center;

			img {
				height: auto;
			}
		}
	}

	& .faq-wrapper {
		& .card {
			background-color: $white;
			box-shadow: 0px 8px 25px 0px rgba(172, 176, 181, 0.2);
			border: none;
			border-radius: 0px;
			& .card-header {
				background-color: transparent;
				border: none;
				font-size: 18px;
				font-weight: 600;
				color: $heading-color;
				padding: 21px 30px;
				@media #{$lg} {
					font-size: 16px;
					line-height: 26px;
					padding: 10px 20px;
				}
				& .toggle_btn {
					float: left;
					margin-right: 15px;
					&:after {
						content: '\f067';
						font-family: 'Font Awesome 5 Free';
						font-weight: 600;
						display: inline-block;
						font-size: 15px;
						color: $heading-color;
						@include transition(0.3s);
					}
				}
			}
			& .card-body {
				padding-top: 0px;
			}
		}
		& .card .card-header[aria-expanded='true'] .toggle_btn:after {
			content: '\f068';
			color: $theme-color;
		}
		& .card .card-header[aria-expanded='true'] {
			color: $theme-color;
		}
	}
}
