@import "@/assets/scss/style";

.logoWrapper {
	width: 100%;
}

.logo {
	width: 100%;
	max-height: 50px;

	@media #{$xl} {
		max-height: 80px;
	}
}
