@import "@/assets/scss/style";
.features-area-v2 {
	padding-top: 245px;
	overflow: hidden;
	padding-top: 110px;

	& .features-item {
		& .img {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 21px;

			& img, div {
				width: 110px;
				height: 110px;
				border: 5px solid $white;
				border-radius: 50%;
				box-shadow: 0px 12px 30px 0px rgba(174, 184, 198, 0.2);
				background-color: $theme-color;
				@include transition(.2s);
			}

			& div {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			& i {
				color: $white;
			}
		}
		& .info {
			& h4 {
				margin-bottom: 9px;
			}
		}

		&:hover{
			.img > div {
				background-color: $heading-color;
			}
		}
	}
	& .features-wrapper {
		position: relative;
		z-index: 1;
		&:after {
			position: absolute;
			content: '';
			top: 20px;
			left: 16%;
			width: 100%;
			height: 100%;
			background: url(/static/img/features/line.png) no-repeat;
			z-index: -1;
			@media #{$lg} {
				width: 75%;
			}
			@media #{$xm} {
				display: none;
			}
		}
	}
}
